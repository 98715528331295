import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output,
  SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import {IPermission} from 'src/app/models/interfaces/i-permissions';
import { IRole } from 'src/app/models/interfaces/i-role';
import { PermissionsService } from 'src/app/services/permissions.service';
import { SessionService } from 'src/app/services/session.service';
import { ModalAddRoleComponent } from './modal-add-role/modal-add-role.component';
import {catchError, finalize, forkJoin, of, tap} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {OverlayService} from "@clavisco/overlay";
import {RolesService} from "../../../../services/roles.service";
import {UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.sass'],
})
export class RoleComponent implements OnInit, OnChanges {
  selectedRole!: IRole;
  roleList!: IRole[];

  allSelected: boolean = false

  permissionFilter = new UntypedFormControl('');
  permissions: IPermission[];

  permissionsFiltered: IPermission[] = [];

  @Input() ActualTabIndex!: number
  @Output() OpenAddModal: EventEmitter<Function> = new EventEmitter<Function>();

  constructor(
    private dialog: MatDialog,
    private permissionService: PermissionsService,
    private rolesService: RolesService,
    private alertService: AlertsService,
    private sessionService: SessionService,
    private clBlockUI: OverlayService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.OpenAddModal.emit(() => {
      this.OpenDialog()
    });
  }

  ngOnInit(): void {
    this.loadInitialData();
  }

  OpenDialog(): void {

    const dialogRef = this.dialog.open(ModalAddRoleComponent, {
      width: '450px',
      maxHeight: `85vh`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.GetRoleList();
    });

  }

  getPermissionsFilter(){

    let permissionFilter = this.permissionFilter.value;
    if (!permissionFilter || permissionFilter == '') {
      this.permissionsFiltered = [...this.permissions];
      return;
    }
    this.permissionsFiltered = [...this.permissions.filter(x => x.Description.toLowerCase().includes(permissionFilter.toLowerCase()) || x.Description.toLowerCase().includes(permissionFilter.toLowerCase()))]
  }

  loadInitialData(){
    let perms = this.permissionService.GetAllPermissions()
    let roles = this.rolesService.GetRoles();

    forkJoin([
      perms.pipe(catchError(()=> of(null))),
      roles.pipe(catchError(()=> of(null)))
    ]).pipe(finalize ( () => { }),
      tap(([respPerms, respRoles])=>{
        if(respPerms){
          this.permissions = respPerms.Data.filter(e => e.IsActive)
          this.permissionsFiltered = [...this.permissions]
        }
        if(respRoles){
          this.roleList= respRoles.Data;
        }
      })).subscribe();
  }
  GetRoleList(): void {
    this.rolesService.GetRoles().pipe(finalize(() => { }),
      tap((data: ICLResponse<IRole[]>)=>{
        if (data.Data) {
          this.roleList = data.Data;
          setTimeout((): void => this.changeDetectorRef.detectChanges());
        } else {
          this.alertService.Toast({ message: data.Message, type: CLToastType.ERROR })
        }
      }),catchError((err) => {
        console.log(err)
        return [];
      })).subscribe();
  };

  loadPermissionsByRole(role: IRole){
    if(role){
      this.clBlockUI.OnGet("Obteniendo asignaciones")
      this.permissionService.GetPermissionsByRole(role.Id).pipe(finalize ( () => { this.clBlockUI.Drop() }),
        tap((data: ICLResponse<IPermission[]>)=>{
          if(data.Data){

            const permissionsByRoleSelected = data.Data.map(e => e.Name)

            this.permissions.forEach(e =>  e.IsAssigned  = permissionsByRoleSelected.includes(e.Name))

            this.allSelected = !this.permissions.some(e => !e.IsAssigned)

            this.permissionsFiltered = [...this.permissions]


          }
        }),catchError((err) => {
          return [];
        })).subscribe();
    }
  }

  ChargeRole(role: IRole): void {
    this.selectedRole = role
    this.loadPermissionsByRole(role)
  }

  isSelected(): boolean {
    return this.selectedRole != null;
  }

  checkedRol(){
    if(this.selectedRole == null){
      this.alertService.Toast({ message:`Primero debe seleccionar un rol` , type: CLToastType.WARNING });
      return;
    }
  }

  setAllPermissions(){
    this.allSelected = !this.allSelected
    this.permissions = this.permissions.map(e => { e.IsAssigned = this.allSelected; return e; })
    this.PatchPermissionsByRole();
  }

  SelectedPermission(perm: IPermission){

    const index = this.permissions.findIndex(e => e.Id == perm.Id)

    const indexFiltered = this.permissionsFiltered.findIndex(e => e.Id == perm.Id)

    if(index != -1){
      this.permissions[index].IsAssigned = !this.permissions[index].IsAssigned


      this.permissionsFiltered[indexFiltered].IsAssigned =  this.permissions[index].IsAssigned

      this.allSelected = !this.permissions.some(e => !e.IsAssigned)
    }

    this.PatchPermissionsByRole()
  }
  PatchPermissionsByRole( ){
    this.clBlockUI.OnGet("Actualizando permisos");
    let permissionIds = "";
    this.permissions.forEach((e) => e.IsAssigned? permissionIds += e.Id + ";": "" );
    this.permissionService.PostPermissionByRole(this.selectedRole!.Id, permissionIds != "" ? permissionIds.substring(0, permissionIds.length - 1) : "")
      .pipe(finalize ( () => { this.clBlockUI.Drop() }),
        tap((data)=>{
          this.alertService.Toast({ message:`Permisos actualizados` , type: CLToastType.SUCCESS })
        }),catchError((err) => {
          return [];
        })).subscribe();
  }
}
