import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AlertsService, CLToastType } from '@clavisco/alerts';
import { IRole } from 'src/app/models/interfaces/i-role';
import {IUsers} from 'src/app/models/interfaces/i-users';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import {catchError, finalize, forkJoin, of, tap} from "rxjs";
import {Structures} from "@clavisco/core";
import ICLResponse = Structures.Interfaces.ICLResponse;
import {RolesService} from "../../../../services/roles.service";
import {UntypedFormControl} from "@angular/forms";
import {OverlayService} from "@clavisco/overlay";

@Component({
  selector: 'app-assing-role',
  templateUrl: './assing-role.component.html',
  styleUrls: ['./assing-role.component.sass'],
})
export class AssingRoleComponent implements OnInit {
  selectedUser!: IUsers;
  userList!: IUsers[];
  userFilter: string = ''

  allSelected: boolean = false

  roleFilter = new UntypedFormControl('');
  roles: IRole[];

  rolesFiltered: IRole[] = [];

  @Input() HavePermission!: Function

  constructor(
    private userService: UserService,
    private permissionService: PermissionsService,
    private rolesService: RolesService,
    private alertService: AlertsService,
    private clBlockUI: OverlayService
  ) { }

  ngOnInit(): void {

    this.loadInitialData();
  }

  getRolesFilter(){

    let permissionFilter = this.roleFilter.value;
    if (!permissionFilter || permissionFilter == '') {
      this.rolesFiltered = [...this.roles];
      return;
    }
    this.rolesFiltered = [...this.roles.filter(x => x.Description.toLowerCase().includes(permissionFilter.toLowerCase()) || x.Description.toLowerCase().includes(permissionFilter.toLowerCase()))]
  }

  loadInitialData(){
    let roles = this.rolesService.GetRoles();
    let users = this.userService.Get();

    forkJoin([
      roles.pipe(catchError(()=> of(null))),
      users.pipe(catchError(()=> of(null)))
    ]).pipe(finalize ( () => { }),
      tap(([respRoles, respUsers])=>{
        if(respRoles){
          this.roles = respRoles.Data.filter(e => e.IsActive)
          this.rolesFiltered = [...this.roles]
        }
        if(respUsers){
          this.userList= respUsers.Data;
        }
      })).subscribe();
  }

  GetUser(): void {
    if(this.userFilter == ''){
      this.userService.Get().pipe(
        finalize(() => {
        }),
        tap((data: ICLResponse<IUsers[]>) => {
          this.userList = data.Data;
        }), catchError((err) => {
          this.alertService.Toast({ message: err, type: CLToastType.ERROR });
          return [];
        } )
      ).subscribe();
    } else {
      this.userService.GetUsersP(this.userFilter, 0, 20).pipe(
        tap((data )=> {
          if(data){
            this.userList = data.Data
          }
        }),
        finalize(()=> {
        })
      ).subscribe();
    }
  }

  ChargeUser(user: IUsers): void {
    this.selectedUser = user;
    this.loadRolessByUser(user)
  }

  isSelected(): boolean {
    return this.selectedUser != null;
  }

  checkedUser(){
    if(this.selectedUser == null){
      this.alertService.Toast({ message:`Primero debe seleccionar un usuario` , type: CLToastType.WARNING });
      return;
    }
  }
  loadRolessByUser(user: IUsers){
    if(user){
      this.clBlockUI.OnGet("Obteniendo asignaciones")
      this.rolesService.GetRoleByUser(user.Id).pipe(
        finalize ( (): void => this.clBlockUI.Drop()),
        tap((data: ICLResponse<IRole[]>)=>{
          if(data.Data){

            const rolesByUserSelected = data.Data.map(e => e.Id)

            this.roles.forEach(e =>  e.IsAssigned  = rolesByUserSelected.includes(e.Id))

            this.allSelected = !this.roles.some(e => !e.IsAssigned)

            this.rolesFiltered = [...this.roles]
          }
        }),catchError((err) => {
          return [];
        })).subscribe();
    }
  }

  GetFullName(user: IUsers): string {
    return `${user.Name} ${user.LastName}`
  }


  setAllRoles($event:any){
    this.allSelected = !this.allSelected
    if(this.allSelected){
      this.roles.forEach(x=>x.IsAssigned = true)
    }else{
      this.roles.forEach(x=>x.IsAssigned = false)
    }
    this.PatchRolesByUser();
  }

  SelectedRole($event:any,role: IRole){
    const index = this.roles.findIndex(e => e.Id == role.Id)

    const indexFiltered = this.roles.findIndex(e => e.Id == role.Id)

    if(index != -1){
      this.roles[index].IsAssigned = !this.roles[index].IsAssigned


      this.rolesFiltered[indexFiltered].IsAssigned =  this.roles[index].IsAssigned

      this.allSelected = !this.roles.some(e => !e.IsAssigned)
    }

    this.PatchRolesByUser()
  }
  PatchRolesByUser( ){
    const rolesByUserSelected = this.roles.filter(e => e.IsAssigned).map(e => e.Id);
    this.clBlockUI.OnGet();
    this.rolesService.StoreRoleByUser({
      EntityId: this.selectedUser.Id,
      Data: rolesByUserSelected.toString(),
    }).pipe(
      finalize((): void => this.clBlockUI.Drop())
    ).subscribe({
      next: (data): void => {
        this.alertService.Toast({ message: 'Roles Actualizados', type: CLToastType.SUCCESS });
        this.ChargeUser(this.selectedUser)
      },
      error: (err: HttpErrorResponse): void => {
        this.alertService.Toast({
          message: `${err.error['error_description']}`,
          type: CLToastType.ERROR })
      }
    });
  };
}
