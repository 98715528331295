<!-- <div class="sidebar ">
    <div *ngFor="let menu of menuItems" class="menu-item">
        <a *ngIf="menu.Url && (menu.SubMenu.length === 0) && menu.ParentId === 0 && menu.HavePerm" mat-button
            fxLayout="row" fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.Id"
            class="text-left">
            <mat-icon class="p-2 m-3">{{menu.IconClass}}</mat-icon>
            <span class="p-2 menu-title">{{menu.Name}}</span>
        </a>
        <a *ngIf="!menu.Url && (menu.SubMenu.length === 0) && menu.ParentId === 0 && menu.HavePerm" mat-button
            fxLayout="row" fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.Id"
            class="text-left">
            <mat-icon class="m-3">{{menu.IconClass}}</mat-icon>
            <span class="p-2 menu-title">{{menu.Name}}</span>
        </a>
        <a *ngIf="(menu.SubMenu.length > 0) && menu.ParentId === 0 && menu.HavePerm" mat-button fxLayout="row"
            fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.Id" class="text-left">
            <mat-icon class="m-1">{{menu.IconClass}}</mat-icon>
            <span class="menu-title">{{menu.Name}}</span>
            <mat-icon class="p-2 menu-expand-icon transition-2">expand_more</mat-icon>
        </a>
        <div *ngIf="(menu.SubMenu.length > 0)" class="sub-menu" [id]="'sub-menu-'+menu.Id">
            <app-sidenav-menu [menuItems]="menu.SubMenu" [menuParentId]="menu.Id"></app-sidenav-menu>
        </div>
        <a *ngIf="(menu.SubMenu.length === 0) && menu.ParentId > 0 && menu.HavePerm" mat-button fxLayout="row"
            fxLayoutAlign="start center" (click)="onClick(menu)" [id]="'menu-item-'+menu.Id" class="text-left">
            <mat-icon class="mr-1 size-icon">{{menu.IconClass}}</mat-icon>
            <span class="menu-title">{{menu.Name}}</span>
        </a>
    </div>
</div> -->
<cl-menu [Id]="MenuId"
         [User]="User"
         [Logo]="Logo"
         [Nodes]="MenuNodes">
  <div class="main-content">
    <mat-toolbar id="tool">
      <mat-toolbar-row class="header-items">
        <div class="item-title">
          <!--<ng-template [ngIf]="!sidenav.opened" [ngIfElse]="opened">-->
            <button mat-icon-button  (click)="ToggleMenu()">
              <mat-icon>menu</mat-icon>
            </button>
          <!--</ng-template>-->
          <!--<ng-template #opened>
            <button mat-icon-button>
              <mat-icon (click)="sidenav.toggle()">chevron_left</mat-icon>
            </button>
          </ng-template>-->
          <h1>{{ 'Administrador de aplicaciones' | uppercase}}</h1>
        </div>
        <div class="item-user-logued">
          <h5 class="username">
            <mat-icon class="mr-1">
              person
            </mat-icon>
            {{logedUserName}}
          </h5>
          <a mat-list-item (click)="LogOut()">
            <button mat-icon-button class="exit-button">
              <mat-icon>exit_to_app</mat-icon>
            </button>
          </a>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
    <div class="home-content">
      <div *ngIf="!showHomeMenu; else Menu">
        <router-outlet></router-outlet>
      </div>
    </div>
    <ng-template #Menu>
      <div id="home-menu">
        <div class="row-container">
          <div class="border welcome-card">
            <div class="flex-row">
                <div class="welcome-title">
                </div>
              <div>
                <img src="../../../../assets/image/cl-manager.png" class="logo">
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</cl-menu>
