import {Component, Input, OnInit, Inject, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from '@clavisco/linker';
import { IMenuNode } from '@clavisco/menu';
import { PermissionCode, AppConstants } from 'src/app/common/constants';
import menu from 'src/app/common/menu';
import { IFiles } from 'src/app/models/interfaces/i-file';
import { IMenuPath } from 'src/app/models/interfaces/i-menu-paths';
import { FileService } from 'src/app/services/file.service';
import { MenuService } from 'src/app/services/menu.service';
import { SessionService } from 'src/app/services/session.service';
import { faBitbucket, IconDefinition } from '@fortawesome/free-brands-svg-icons'
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CLPrint, GetError, Structures } from '@clavisco/core';
import { AlertsService, CLModalType, ModalService } from '@clavisco/alerts';
import { LogService } from 'src/app/services/log.service';
import { OverlayService } from "@clavisco/overlay";
import { catchError, finalize, tap } from "rxjs";
import ICLResponse = Structures.Interfaces.ICLResponse;
import { IPermission } from "../../models/interfaces/i-permissions";




@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent implements OnInit {

  showHomeMenu: boolean = false;
  logedUserName!: string
  faBitbucket: IconDefinition = faBitbucket
  faServer: IconDefinition = faServer
  sidenavMenuItems: IMenuPath[] = [];

  Toggle: boolean = true;
  MenuNodes: IMenuNode[] = menu
  MenuId = "main-menu"
  Logo = "./../../assets/image/clavis-white.png"
  User = ""
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: []
  }

  @Input('menuItems') menuItems!: IMenuPath[];
  @Input('menuParentId') menuParentId: any;
  filesBlob: IFiles[] = [];
  submenu!: IMenuPath;

  isMenuOpened!: boolean;

  constructor(
    private router: Router,
    private fileService: FileService,
    @Inject('LinkerService') private linkerService: LinkerService,
    private menuService: MenuService,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private permissionService: PermissionsService,
    private clBlockUI: OverlayService,
    private clAlertService: ModalService,
    private logsService: LogService,
  ) {
    this.router.events.subscribe({
      next: (callback) => {
        if (callback instanceof NavigationEnd) {
          if (callback.url === '/index') {
            this.showHomeMenu = true;
          } else {
            this.showHomeMenu = false;
          }
        }
      },
    });
  }

  ngOnInit(): void {
    this.cd.detectChanges();
    this.logedUserName = this.sessionService.GetUserName();
    this.GetUserPermissions();
    this.ToggleMenu();
  }

  toggleMenuItem(menuId: number) {
   let menuItem = document.getElementById('menu-item-' + menuId);
   let subMenu = document.getElementById('sub-menu-' + menuId);
   if (subMenu) {
     if (subMenu.classList.contains('show')) {
       subMenu.classList.remove('show');
       menuItem?.classList.remove('expanded');
     }
     else {
       subMenu.classList.add('show');
       menuItem?.classList.add('expanded');
     }
   }
  }


  public ToggleMenu(): void {
    this.isMenuOpened = !this.isMenuOpened;
    this.linkerService.Publish({
      CallBack: CL_CHANNEL.DATA_LINE_1,
      Data: JSON.stringify(this.isMenuOpened),
      Target: this.MenuId
    });
  }



  GetUserPermissions(): void {
    try {
      if (this.sessionService.GetUserId()) {
        this.clBlockUI.OnGet();
        this.permissionService.GetPermissionsByUser(this.sessionService.GetUserId()).pipe(finalize(() => { this.clBlockUI.Drop() }),
          tap((data: ICLResponse<IPermission[]>) => {
            if (data.Data) {
              this.sessionService.SetPermissions(data.Data)
            }
          }), catchError((err) => {
            return [];
          })).subscribe();
      }
    } catch (error) {

      CLPrint(GetError(error));
    }
  }


  getMenu() {
    // this.sidenavMenuItems = menu;
    this.menuService.getMenu()
      .subscribe({
        next: (data) => {
          this.menuService.Data.menu = data;
          this.menuService.Menu.next(data)
        },
        error: (error: string) => {
          console.log(error)
        },
      });
  }

  HabilitedOption(code: PermissionCode[]): boolean {
    let condition: boolean = false;
    code.forEach((element) => {
      if (this.GetUserPermissons().includes(element)) {
        condition = true;
      }
    });
    return true;
  }

  LogOut(): void {
    this.sessionService.RemoveSession();
    this.router.navigateByUrl('/login');
  }

  ViewProyects(): void {
    this.router.navigate(['proyects'], { relativeTo: this.route });
  }

  ViewPermissions(): void {
    this.router.navigate(['permissions'], { relativeTo: this.route });
  }

  ViewUsers(): void {
    this.router.navigate(['users'], { relativeTo: this.route });
  }

  ViewDevelopers(): void {
    this.router.navigate(['developers'], { relativeTo: this.route });
  }

  ViewServers(): void {
    this.router.navigate(['servers'], { relativeTo: this.route });
  }

  ViewBitbucketIntegration() {
    this.router.navigate(['bitbucket'], { relativeTo: this.route })
  }

  ViewWindowsServios() {
    this.router.navigate(['services'], { relativeTo: this.route })
  }

  GetUserPermissons(): string[] {
    const permissions: string[] = [];
    this.sessionService.GetCompleteToken().Permissions.forEach((element) => {
      permissions.push(element.Name);
    });
    return permissions;
  }

}
