import { CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown } from "@clavisco/linker";
import { AlertsService, CLModalType, CLToastType, ModalService } from "@clavisco/alerts";
import { finalize, map, Observable, startWith, Subscription } from "rxjs";
import { MapDisplayColumns, MappedColumns } from "@clavisco/table";
import { ICLTableButton } from "@clavisco/table/lib/table.space";
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { OverlayService } from "@clavisco/overlay";
import { Structures } from "@clavisco/core";

import { UdoHistoryAction, UdoHistoryStatus, UdoHistoryTransactionsStatus } from "../../../../common/constants";
import { IUsersDefineObject } from "../../../../models/interfaces/sap-object/i-users-define-object";
import { ISapUdoHistories } from "../../../../models/interfaces/sap-object/i-sap-udo-histories";
import { IApplication } from "../../../../models/interfaces/sap-object/i-application";
import { ApplicationService } from "../../../../services/application.service";
import { SAPObjectService } from "../../../../services/sap-object.service";
import { CommonService } from "../../../../services/common.service";
import { UserService } from "../../../../services/user.service";

@Component({
  selector: 'app-history-udos',
  templateUrl: './history-udos.component.html',
  styleUrls: ['./history-udos.component.sass']
})
export class HistoryUdosComponent implements OnInit {
  // #region Properties
  subscription: Subscription = new Subscription();
  sapUDOsHistory: ISapUdoHistories[] = [];
  // #endregion

  // #region Table History UDOs
  idUDOsHistoryTable: string = 'udos-history-table';
  udosHistoryTableCurrentPage: number = 1;
  pageSizeOptions: number[] = [25, 50, 100];
  itemsPeerPage: number = this.pageSizeOptions[0];
  rowsReturned: number = this.pageSizeOptions[0];
  recordsCount: number = 0;
  startPos: number = 0;
  shouldPaginateRequest: boolean = false;
  scrollHeight: string = '450px';
  displayedColumnsHistoryUDOs: MappedColumns;
  buttonsUDOsHistoryTable: ICLTableButton[] = [
    {
      Title: 'Descargar Archivo de Solicitud',
      Action: Structures.Enums.CL_ACTIONS.OPTION_1,
      Icon: 'download',
      Color: 'primary'
    },
    {
      Title: 'Descargar Resumen de la Solicitud',
      Action: Structures.Enums.CL_ACTIONS.OPTION_2,
      Icon: 'download',
      Color: 'primary'
    }
  ];
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };
  // #endregion

  // #region Information for UDOs history table filters
  listFilteredApplications: Observable<IApplication[]>;
  applications: IApplication[] = [];
  listFilteredUDOsCreatorUsers: Observable<IUsersDefineObject[]>;
  udosCreatorUsers: IUsersDefineObject[] = [];
  typeActionsUDOsHistory = UdoHistoryAction;
  typeStatusUDOsHistory = UdoHistoryStatus;
  typeTransactionsStatusUDOsHistory = UdoHistoryTransactionsStatus;

  listNameAction: { Id: number, Value: string }[] = [
    { Id: UdoHistoryAction.Create, Value: 'Crear' },
    { Id: UdoHistoryAction.Update, Value: 'Actualizar' },
    { Id: UdoHistoryAction.Delete, Value: 'Borrar' },
    { Id: UdoHistoryAction.Replicate, Value: 'Replicar' }
  ];

  listNameStatus: { Id: number, Value: string }[] = [
    { Id: UdoHistoryStatus.Success, Value: 'Exitoso' },
    { Id: UdoHistoryStatus.Failed, Value: 'Fallido' }
  ];

  listNameTransactionsStatus: { Id: number, Value: string }[] = [
    { Id: UdoHistoryTransactionsStatus.AllComplete, Value: 'Todo completo' },
    { Id: UdoHistoryTransactionsStatus.AllFailed, Value: 'Todo fallido' },
    { Id: UdoHistoryTransactionsStatus.PartialComplete, Value: 'Parcial completo' }
  ];

  formHistoryUDOs: FormGroup = new FormGroup({
    Application: new FormControl(undefined),
    TypeAction: new FormControl(null),
    State: new FormControl(null),
    TransactionsStatus: new FormControl(null),
    CreatedBy: new FormControl(null),
    InitDate: new FormControl(null),
    EndDate: new FormControl(null)
  });
  // #endregion

  constructor(
    private  userService: UserService,
    public alertService: AlertsService,
    private modalService: ModalService,
    private commonService: CommonService,
    private overlayService: OverlayService,
    private sapObjectService: SAPObjectService,
    private applicationService: ApplicationService,
    @Inject('LinkerService') private linkerService: LinkerService
  ){
    this.displayedColumnsHistoryUDOs = MapDisplayColumns<ISapUdoHistories, null>({
      dataSource: this.sapUDOsHistory,
      renameColumns: { ApplicationName: 'Nombre de Aplicacion', ActionName: 'Acción',
        TransactionsStatusName: 'Estado de las Transacciones', CreatedBy: 'Creado por',
        FormattedCreatedDate: 'Fecha de Creación' },
      ignoreColumns: [ 'Id', 'IsActive', 'MaxQtyRowsFetch', 'FileObjectUrl', 'Summary', 'UpdateDate', 'UpdatedBy',
        'ApplicationId', 'Status', 'Action', 'TransactionsStatus', 'CreatedDate', 'StateName' ]
    });
  };

  ngOnInit(): void {
    Register<CL_CHANNEL>(this.idUDOsHistoryTable, CL_CHANNEL.INPUT, this.GetElementsRecords, this.callbacks);
    Register<CL_CHANNEL>(this.idUDOsHistoryTable, CL_CHANNEL.OUTPUT, this.OptionsUDOsHistoryTable, this.callbacks);
    this.subscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => this.alertService.ShowAlert({ HttpErrorResponse: error })
      })
    );
    this.applicationService.Get().pipe(
      map((apps: Structures.Interfaces.ICLResponse<IApplication[]>) => apps.Data)
    ).subscribe((data: IApplication[]): void => {
      this.applications = data;
    });

    this.userService.GetAllUsersDefineObjects().pipe(
      map((users: Structures.Interfaces.ICLResponse<IUsersDefineObject[]>) => users.Data)
    ).subscribe((data: IUsersDefineObject[]): void => {
      this.udosCreatorUsers = data;
    });
  };

  LoadDataUDOsHistoryTable(): void {
    this.sapUDOsHistory.map((obj: ISapUdoHistories): void => {
      obj.ActionName = this.listNameAction.find(
        (action) => action.Id === obj.Action)?.Value
      obj.StateName = this.listNameStatus.find(
        (status) => status.Id === obj.Status )?.Value
      obj.TransactionsStatusName = this.listNameTransactionsStatus.find(
        (transaction) => transaction.Id === obj.TransactionsStatus)?.Value
      obj.FormattedCreatedDate = this.commonService.FormatDate(obj.CreatedDate)
    });

    const NEW_TABLE_STATE = {
      CurrentPage: this.udosHistoryTableCurrentPage,
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.sapUDOsHistory,
      RecordsCount: this.recordsCount
    };

    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.idUDOsHistoryTable,
      Data: JSON.stringify(NEW_TABLE_STATE)
    });
  };

  ClearUDOsHistoryTable(): void {
    this.sapUDOsHistory = [];
    this.formHistoryUDOs.reset();
    this.LoadDataUDOsHistoryTable();
    this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'Se limpio correctamente el filtrado' });
  };

  handleUDOsHistoryFiltering(): void {
    if(this.formHistoryUDOs.invalid){
      this.formHistoryUDOs.markAllAsTouched();
      return this.alertService.Toast({ message: 'El formulario es inválido, verifica los campos', type: CLToastType.ERROR });
    }

    this.overlayService.OnPost();
    const selectedApplication: IApplication | undefined = this.applications.find((app: IApplication) =>
      app.Name === this.formHistoryUDOs.get('Application')?.value );

    const valueTypeAction = this.formHistoryUDOs.get('TypeAction')?.value;
    const valueState = this.formHistoryUDOs.get('State')?.value;
    const valueTransactionsStatus = this.formHistoryUDOs.get('TransactionsStatus')?.value;

    const selectedAllActions: boolean = valueTypeAction === 'allActions';
    const selectedAllStates: boolean = valueState === 'allStates';
    const selectedAllTransactionsStatus: boolean = valueTransactionsStatus === 'allTransactionsStatus';

    const formData =
      {
        ...this.formHistoryUDOs.value,
        Application: selectedApplication?.Id,
        TypeAction: selectedAllActions ? null : valueTypeAction,
        State: selectedAllStates ? null : valueState,
        TransactionsStatus: selectedAllTransactionsStatus ? null : valueTransactionsStatus
      };

    this.sapObjectService.GetSAPUDOsHistories(formData, this.startPos, this.rowsReturned).pipe(
      finalize(() => this.overlayService.Drop())
    ).subscribe({
      next: (data: Structures.Interfaces.ICLResponse<ISapUdoHistories[]> ): void => {
        this.sapUDOsHistory = data?.Data;
        this.recordsCount = data.Data.length > 0 ? data.Data[0].MaxQtyRowsFetch : 0;
        this.LoadDataUDOsHistoryTable();
        this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'Filtros Aplicados' });
      },
      error: ( err ): void => this.alertService.ShowAlert({ HttpErrorResponse: err }),
      complete: (): void => {}
    });
  };

  // #region Dynamic application filtering
  GetFilteredApplications(): void {
    this.listFilteredApplications = this.formHistoryUDOs.get('Application')!.valueChanges.pipe(
      startWith(''),
      map((value: string | null): IApplication[] =>
        (value ? this.FilterApplications(value) : this.applications.slice()))
    );
  };

  private FilterApplications(value: string): IApplication[] {
    const filterValue: string = value?.toLowerCase();
    return this.applications.filter((app: IApplication) => app?.Name.toLowerCase().includes(filterValue));
  };
  // #endregion

  // #region Dynamic filtering of the corresponding user of UDOs
  GetFilteredUDOsCreatorUsers(): void {
    this.listFilteredUDOsCreatorUsers = this.formHistoryUDOs.get('CreatedBy')!.valueChanges.pipe(
      startWith(''),
      map((value: string | null): IUsersDefineObject[] =>
        (value ? this.FilterUDOsCreatorUsers(value) : this.udosCreatorUsers.slice()))
    );
  };

  private FilterUDOsCreatorUsers(value: string): IUsersDefineObject[] {
    const filterValue: string = value?.toLowerCase();
    return this.udosCreatorUsers.filter((user: IUsersDefineObject) => user?.CreatedBy.toLowerCase().includes(filterValue));
  };
  // #endregion

  // #region Functions corresponding to the functionalities of the UDOs History table
  OptionsUDOsHistoryTable = (_event: ICLEvent): void => {
    const event = JSON.parse(_event.Data);
    const sapUdoHistory: ISapUdoHistories = JSON.parse(event.Data);

    switch (event.Action) {
      // Download file of the desired history object
      case Structures.Enums.CL_ACTIONS.OPTION_1:
        const fileObjectUrl: string = sapUdoHistory?.FileObjectUrl;
        if(fileObjectUrl.length > 0 && fileObjectUrl != ''){
          window.location.href = fileObjectUrl;
          return this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'Descargando el archivo del objeto' })
        } else {
          return this.alertService.Toast({ type: CLToastType.ERROR, message: 'No existe un archivo válido para este objeto' })
        }
      // Validate summary
      case Structures.Enums.CL_ACTIONS.OPTION_2:
        const fileExists: boolean = this.commonService.isURL(sapUdoHistory?.Summary);
        if(fileExists){
          // Download the file registered in the summary
          window.location.href = sapUdoHistory?.Summary;
          return this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'Descargando el archivo de resumen' })
        } else {
          // Open a modal with the information stored in the summary
          this.modalService.Open({
            type: CLModalType.INFO,
            title: 'No existe un archivo válido de resumen',
            subtitle: sapUdoHistory?.Summary,
            disableClose: false,
            options: {
              ConfirmButton: {
                Action: Structures.Enums.CL_ACTIONS.CONTINUE,
                Color: 'primary',
                Title: 'Continuar',
                Data: '',
                Icon: ''
              }
            }
          });
        }
    }
  };

  GetElementsRecords = (_event: ICLEvent): void => {
    if (_event) {
      const INCOMMING_TABLE_STATE = JSON.parse(_event.Data);
      this.rowsReturned = INCOMMING_TABLE_STATE.ItemsPeerPage;
      this.startPos = INCOMMING_TABLE_STATE.CurrentPage;
      this.handleUDOsHistoryFiltering();
    }
  };
  // #endregion

  ClearApplicationSelection(fieldName: string): void {
    const applicationField = this.formHistoryUDOs.get(fieldName);
    if(applicationField?.value){
      applicationField.setValue('');
    }
  };
}
