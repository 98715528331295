import {CL_CHANNEL, ICLCallbacksInterface, ICLEvent, LinkerService, Register, Run, StepDown} from "@clavisco/linker";
import {catchError, combineLatestWith, distinctUntilChanged, filter, finalize, forkJoin, map, Observable, of,
  startWith, Subscription} from "rxjs";
import {AlertsService, CLToastType, ModalService} from "@clavisco/alerts";
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MapDisplayColumns, MappedColumns} from "@clavisco/table";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ICLTableButton} from "@clavisco/table/lib/table.space";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {OverlayService} from "@clavisco/overlay";
import {Structures} from "@clavisco/core";

import {IServiceLayerConnection} from "../../../../models/interfaces/sap-object/i-service-layer-connection";
import {IFilterUdo, IUserDefinedField} from "../../../../models/interfaces/sap-object/i-user-defined-field";
import {ISapObjectResStructure} from "../../../../models/interfaces/sap-object/i-sap-object-res-structure";
import {ModalSummaryResponseComponent} from "../modal-summary-response/modal-summary-response.component";
import {UdoConnectionsModalComponent} from "../udo-connections-modal/udo-connections-modal.component";
import {ModalValidValuesMDComponent} from "../modal-valid-values-md/modal-valid-values-md.component";
import {IUserDefinedTable} from "../../../../models/interfaces/sap-object/i-user-defined-table";
import {ISapUdoHandler} from "../../../../models/interfaces/sap-object/i-sap-udo-handler";
import {IUdoConnection} from "../../../../models/interfaces/sap-object/i-udo-connection";
import {ViewUdfsModalComponent} from "../view-udfs-modal/view-udfs-modal.component";
import {PermissionCode, UdoHistoryAction} from "../../../../common/constants";
import {ApplicationService} from "../../../../services/application.service";
import {ConnectionService} from "../../../../services/connection.service";
import {IApplication} from "../../../../models/interfaces/i_applications";
import {SAPObjectService} from "../../../../services/sap-object.service";
import {SettingService} from "../../../../services/setting.service";
import {SessionService} from "../../../../services/session.service";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Component({
  selector: 'app-replicate-objects',
  templateUrl: './replicate-objects.component.html',
  styleUrls: ['./replicate-objects.component.sass']
})
export class ReplicateObjectsComponent implements OnInit, OnDestroy {
  subscription: Subscription= new Subscription();
  filterTypes: { Id: number, Value: string }[] = [
    { Id: 0, Value: 'Todos' },
    { Id: 1, Value: 'Tabla SAP' },
    { Id: 2, Value: 'Aplicación' }
  ];
  selectedType!: number;
  tableUdf: string = 'tableUDFs';
  tableUdt: string = 'tableUDTs';
  displayedColumns!: MappedColumns;
  pageSizeOptions: number[] = [50, 100, 150];
  itemsPeerPage: number = this.pageSizeOptions[0];
  recordsCount: number = 0;
  hasPaginator: boolean = true;
  shouldPaginateRequest: boolean = false;
  startPos: number = 0;
  rowsReturned: number = 10;
  callbacks: ICLCallbacksInterface<CL_CHANNEL> = {
    Callbacks: {},
    Tracks: [],
  };

  listUDTsInitial: IUserDefinedTable[] = [];
  listUdts: IUserDefinedTable[] = [];
  listUDFsInitial: IUserDefinedField[] = [];
  listUdfs: IUserDefinedField[] = [];
  listFilteredDatabases:string[] = [];
  listFilteredApp: IApplication[] = [];
  listApplications: IApplication[];
  listConnections!: Observable<IServiceLayerConnection[]>;
  databases: string[] = [];
  filterForm: FormGroup;
  formConnexion: FormGroup;
  selectedConnections: IUdoConnection[] = [];

  Buttons: ICLTableButton[] = [{
    Title: 'Ver valores validos',
    Action: Structures.Enums.CL_ACTIONS.OPTION_1,
    Icon: `info`,
    Color: `primary`
  }];

  /// <summary>
  /// It will be used again in the future, so its flow and/or configurations made are not deleted.
  /// </summary>
  ButtonsUDTsTable: ICLTableButton[] = [
    {
      Title: 'UDFs relacionados',
      Action: Structures.Enums.CL_ACTIONS.OPTION_1,
      Icon: 'checklist',
      Color: 'primary',
    }
  ];

  // #region Permissions
  permissionReplicateUDOs: boolean = false;
  // #endregion

  hidePassword: boolean = true;

  tabIndex: number = 0;
  selectedRegistryUDTs: IUserDefinedTable[] = [];
  selectedRegistryUDFs: IUserDefinedField[] = [];
  displayedColumnsUDTsTable: MappedColumns;
  thereDataUDOs: boolean = false;
  listUDONames: string[] = [];
  listFilteredUDONames!: Observable<string[]>;
  listUDFsTables: string[] = [];
  listFilteredTablesUDFs!: Observable<string[]>;

  constructor(@Inject('LinkerService') private linkerService: LinkerService,
              private alertService: AlertsService,
              private modalService: ModalService,
              private sapObjectService: SAPObjectService,
              private settingService: SettingService,
              private clBlockUI: OverlayService,
              private applicationService: ApplicationService,
              private dialog: MatDialog,
              private connectionService: ConnectionService,
              private sessionService: SessionService,
  ) {
    this.filterForm = new FormGroup({
      Type: new FormControl(null, Validators.required),
      TableName: new FormControl('',  Validators.required),
      NextPos: new FormControl(this.startPos),
      RowsReturned: new FormControl(this.rowsReturned),
      ApplicationId: new FormControl(0, Validators.required),
      ApplicationName: new FormControl(''),
      NameUDO: new FormControl(''),
      TableUDF: new FormControl('')
    });
    this.formConnexion = new FormGroup({
      DataBaseCode: new FormControl('', Validators.required),
      ServerUrl: new FormControl('', Validators.required),
      License: new FormControl('', Validators.required),
      Password: new FormControl('', Validators.required),
    });
    this.displayedColumns = MapDisplayColumns<IUserDefinedField, null>({
      dataSource: this.listUdfs,
      renameColumns: { Name: "Nombre", Description: "Descripción", Type: "Tipo del campo", Size: "Tamaño del campo",
        TableName: "Nombre de la tabla", SubType: "SubTipo", HasValidValues: "Valores Válidos", },
      ignoreColumns: [ "Id", "IsActive", "UpdatedBy", "UpdateDate", "DefaultValue", "FieldID", "EditSize",
        "Mandatory","ValidValuesMD", "IsSelected" ],
      propToLinkWithSelectColumn: 'IsSelected'
    });

    this.displayedColumnsUDTsTable = MapDisplayColumns<IUserDefinedTable, null>({
      dataSource: this.listUdts,
      renameColumns: { TableName: 'Nombre de la Tabla', TableDescription: 'Descripción de la Tabla',
        TableType: 'Tipo de Tabla', Archivable: 'Archivable' },
      ignoreColumns: [ 'ArchiveDateField', 'IsSelected' ],
      propToLinkWithSelectColumn: 'IsSelected'
    });
  };

  ngOnInit(): void {
    Register<CL_CHANNEL>(this.tableUdf, CL_CHANNEL.DATA_LINE_1, this.GetSelectedRecordsUDFs, this.callbacks);
    Register<CL_CHANNEL>(this.tableUdf, CL_CHANNEL.OUTPUT, this.ButtonsEvent, this.callbacks);

    Register<CL_CHANNEL>(this.tableUdt, CL_CHANNEL.DATA_LINE_1, this.GetSelectedRecordsUDTs, this.callbacks);
    Register<CL_CHANNEL>(this.tableUdt, CL_CHANNEL.OUTPUT, this.ButtonsEventUDTTable, this.callbacks);

    this.connectionService.connection$.subscribe((connections: IUdoConnection[]) => this.selectedConnections = connections);
    this.permissionReplicateUDOs = this.sessionService.GetPermissionCodeFromToken().includes(PermissionCode.ReplicateUDOs);
    this.subscription.add(this.linkerService.Flow()
      ?.pipe(StepDown<CL_CHANNEL>(this.callbacks))
      .subscribe({
        next: (callback) =>
          Run(callback.Target, callback, this.callbacks.Callbacks),
        error: (error) => this.alertService.ShowAlert({ HttpErrorResponse: error })
      })
    );
    this.LoadInitialData();
    this.FilterForm();

    // Get or set the value of filters for UDOs
    const filterNameUDOs = this.filterForm.get('NameUDO')?.valueChanges.pipe(distinctUntilChanged());
    const filterTableUDFs = this.filterForm.get('TableUDF')?.valueChanges.pipe(distinctUntilChanged());

    // Subscribe dynamic filters to the same method
    if (filterNameUDOs && filterTableUDFs) {
      this.subscription.add(
        filterNameUDOs.pipe(
          combineLatestWith(filterTableUDFs)
        ).subscribe((): void => {
          this.GetFilteredUDOs();
        })
      );
    }
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  LoadInitialData(): void {
    let connection = this.settingService.GetServiceLayerConnections();
    let application = this.applicationService.Get();
    forkJoin([
      connection.pipe(catchError(async (error)=> this.alertService.ShowAlert({ HttpErrorResponse: error }))),
      application.pipe(catchError(async (error)=> this.alertService.ShowAlert({ HttpErrorResponse: error }))),
    ]).subscribe(([ respConnection, respApp]) => {
      if(respConnection?.Data){
        this.listConnections = of(respConnection.Data);
      }
      if(respApp?.Data){
        this.listApplications = respApp.Data;
        this.listFilteredApp = this.listApplications;
      }
    });
  };

  FilterForm(): void{
    this.formConnexion.controls['DataBaseCode'].valueChanges.pipe(
      filter(item => !!item),
      map(item => item.toLowerCase())
    ).subscribe(filterValue => {
      this.listFilteredDatabases = this.databases.filter(option => option.toLowerCase().includes(filterValue));
    });
    this.filterForm.controls['ApplicationName'].valueChanges.pipe(
      filter(item => !!item),
      map(item => item.toLowerCase())
    ).subscribe(filterValue => {
      this.listFilteredApp = this.listApplications.filter(option => option.Name.toLowerCase().includes(filterValue));
    });
  }

  FilterSapObject = (): void => {
    this.filterForm.get('NameUDO')?.setValue('');
    this.filterForm.get('TableUDF')?.setValue('');
    switch(this.tabIndex){
      case 0:
        this.GetUserDefinedTables();
        break;
      case 1:
        this.GetUserDefinedFields();
        break;
    }
  };

  SelectApplication(app: IApplication): void{
    this.filterForm.get('ApplicationId')?.setValue(app.Id);
  }

  SelectType(type: number): void{
    this.selectedType = type;
    this.filterForm.get('TableName')?.setValue('');
    this.filterForm.get('ApplicationId')?.setValue(0);
    this.filterForm.get('ApplicationName')?.setValue('');
    this.filterForm.get('TableName')?.clearValidators();
    this.filterForm.get('TableName')?.updateValueAndValidity();
    this.filterForm.get('ApplicationName')?.clearValidators();
    this.filterForm.get('ApplicationName')?.updateValueAndValidity();
    this.filterForm.get('ApplicationId')?.clearValidators();
    this.filterForm.get('ApplicationId')?.updateValueAndValidity();
    switch (type){
      case 0:
        this.filterForm.get('TableName')?.clearValidators();
        this.filterForm.get('TableName')?.updateValueAndValidity();
        break;
      case 1:
        this.filterForm.get('TableName')?.setValidators(Validators.required);
        this.filterForm.get('TableName')?.updateValueAndValidity();
        break;
      case 2:
        this.filterForm.get('ApplicationName')?.setValidators(Validators.required);
        this.filterForm.get('ApplicationName')?.updateValueAndValidity();
        this.listFilteredApp = this.listApplications;
        break;
    }
  }

  ClearTableUDF(): void{
    const NEW_TABLE_STATE = {
      Records: [],
      RecordsCount: 0,
    };

    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableUdf,
      Data: JSON.stringify(NEW_TABLE_STATE)
    });
  }

  GetUserDefinedFields = (): void => {
    this.listUdfs = [];
    if(!this.formConnexion.valid || !this.filterForm.valid || this.selectedType == null){
      return this.alertService.Toast({message:'Formulario no válido'})
    }
    let filter: IFilterUdo = this.filterForm.getRawValue();
    const connection: IUdoConnection = this.formConnexion.getRawValue();
    filter = {...filter,...connection};
    this.clBlockUI.OnGet();
    this.sapObjectService.GetUserDefinedFields(filter).pipe(
      finalize(() => this.clBlockUI.Drop())
    ).subscribe({
      next: ( data: ICLResponse<IUserDefinedField[]> ): void => {
        if (data.Data) {
          const formRecords = data.Data.map((udf: IUserDefinedField) => ({ ...udf, IsSelected: false }))
          this.listUdfs = formRecords;
          this.listUDFsInitial = formRecords;
          this.listUdfs.length > 0 && this.HandleUDONames();
        }
        this.LoadTableDataUDF();
      },
      error: (err): void => {
        this.ClearTableUDF();
      },
    });
  }

  ClearTableUDT(): void{
    const NEW_TABLE_STATE = {
      Records: [],
      RecordsCount: 0,
    };

    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableUdt,
      Data: JSON.stringify(NEW_TABLE_STATE)
    });
  }

  GetUserDefinedTables = (): void => {
    this.listUdts = [];
    if(!this.formConnexion.valid || !this.filterForm.valid || this.selectedType == null) {
      return this.alertService.Toast({ type: CLToastType.ERROR, message: 'Formulario no válido'} );
    }
    let filter: IFilterUdo = this.filterForm.getRawValue();
    const connection: IUdoConnection = this.formConnexion.getRawValue();
    filter = {...filter,...connection};
    this.clBlockUI.OnGet();
    this.sapObjectService.GetUserDefinedTables(filter).pipe(
      finalize(() => this.clBlockUI.Drop())
    ).subscribe({
      next: (data: ICLResponse<IUserDefinedTable[]>): void => {
        if (data.Data) {
          const formRecords = data.Data.map((udt: IUserDefinedTable) => ({ ...udt, IsSelected: false }))
          this.listUdts = formRecords;
          this.listUDTsInitial = formRecords;
          this.listUdts.length > 0 && this.HandleUDONames();
        }
        this.LoadTableDataUDT();
      },
      error: (err): void => {
        this.ClearTableUDT();
      },
    });
  };

  RequestSelectedItemsUDTs = (): void => {
    const EVENT: ICLEvent = {
      CallBack: CL_CHANNEL.DATA_LINE_2,
      Target: this.tableUdt,
    } as ICLEvent;
    this.linkerService.Publish(EVENT);
  };

  RequestSelectedItemsUDFs = (): void => {
    const EVENT: ICLEvent = {
      CallBack: CL_CHANNEL.DATA_LINE_2,
      Target: this.tableUdf,
    } as ICLEvent;
    this.linkerService.Publish(EVENT);
  };

  ClearSelectedItemsUDTs = (): void => {
    const EVENT: ICLEvent = {
      CallBack: CL_CHANNEL.FLUSH,
      Target: this.tableUdt,
    } as ICLEvent;
    this.linkerService.Publish(EVENT);
  };

  ClearSelectedItemsUDFs = (): void => {
    const EVENT: ICLEvent = {
      CallBack: CL_CHANNEL.FLUSH,
      Target: this.tableUdf,
    } as ICLEvent;
    this.linkerService.Publish(EVENT);
  };

  GetSelectedRecordsUDTs = (_event: ICLEvent): void => {
    this.selectedRegistryUDTs = JSON.parse(_event.Data);
  };

  GetSelectedRecordsUDFs = (_event: ICLEvent): void => {
    this.selectedRegistryUDFs = JSON.parse(_event.Data);
  };

  LoadTableDataUDT(): void {
    const NEW_TABLE_STATE = {
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.listUdts,
      RecordsCount: this.listUdts.length,
    };

    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableUdt,
      Data: JSON.stringify(NEW_TABLE_STATE)
    });
  };

  LoadTableDataUDF(): void {
    this.listUdfs.map(x=>{
      x.HasValidValues = x.ValidValuesMD.length > 0 ? 'SI' : 'NO';
    });
    const NEW_TABLE_STATE = {
      ItemsPeerPage: this.itemsPeerPage,
      Records: this.listUdfs,
      RecordsCount: this.listUdfs.length,
    };

    this.linkerService.Publish({
      CallBack: CL_CHANNEL.INFLATE,
      Target: this.tableUdf,
      Data: JSON.stringify(NEW_TABLE_STATE)
    });
  };

  ButtonsEvent = (_event: ICLEvent): void => {
    if (_event.Data) {
      const BUTTON_EVENT = JSON.parse(_event.Data);
      const ELEMENT:IUserDefinedField = JSON.parse(BUTTON_EVENT.Data);

      switch (BUTTON_EVENT.Action) {
        case Structures.Enums.CL_ACTIONS.OPTION_1:
          this.OpenModalValidValues(ELEMENT);
          break;
      }
    }
  }

  ButtonsEventUDTTable = (_event: ICLEvent): void => {
    const BUTTON_EVENT = JSON.parse(_event.Data);
    const ELEMENT = JSON.parse(BUTTON_EVENT.Data);

    switch(BUTTON_EVENT.Action){
      case Structures.Enums.CL_ACTIONS.OPTION_1:
        this.OpenViewUDFsModal();
        break;
    }
  };

  OpenViewUDFsModal(): void {
    const dialogRef: MatDialogRef<ViewUdfsModalComponent> = this.dialog.open(ViewUdfsModalComponent, {
      minWidth: '50%',
      height: '60vh',
      autoFocus: 'first-header',
      data: {},
    });

    dialogRef.afterClosed().subscribe({
      next: (): void => {
        this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'Cerrando el modal de vista de UDFs' });
      }
    })
  };

  OpenModalConnections(): void{
    this.dialog.open(UdoConnectionsModalComponent, {
      minWidth: '80%',
      height: `85vh`,
      autoFocus: 'first-header',
      data: {}
    });
  }

  async TabChanged(tabChangeEvent: MatTabChangeEvent): Promise<void> {
    if(this.thereDataUDOs){
      this.filterForm.get('NameUDO')?.setValue('');
      this.filterForm.get('TableUDF')?.setValue('');
      this.thereDataUDOs = false;
      this.listUDONames = [];
      this.listUDFsTables = [];
    }
    this.tabIndex = tabChangeEvent.index;
    this.RequestSelectedItemsUDTs();
    this.RequestSelectedItemsUDFs();
  };

  ReplicateSelectedUDOs = (): void => {
    this.RequestSelectedItemsUDTs();
    this.RequestSelectedItemsUDFs();
    if (!this.selectedRegistryUDTs.length && !this.selectedRegistryUDFs.length)
      return this.alertService.Toast({message: 'No se encontraron UDOs a replicar', type: CLToastType.ERROR})

    if (!this.selectedConnections.length) {
      return this.alertService.Toast({ message: 'Debe agregar una conexión de destino para replicar', type: CLToastType.ERROR })
    }

    const objectsToReplicate: ISapUdoHandler = {
      UserDefinedTables: this.selectedRegistryUDTs,
      UserDefinedFields: this.selectedRegistryUDFs,
      Connections: this.selectedConnections,
      ApplicationId: this.filterForm.get('ApplicationId')?.value || 0,
      Action: UdoHistoryAction.Replicate,
    };

    this.modalService.Question({
      title: 'Replica de UDOs',
      subtitle: `Se replicarán ${ this.selectedRegistryUDTs.length } UDTs y ${ this.selectedRegistryUDFs.length } UDFs para ${ this.selectedConnections.length } conexiones`,
      disableClose: false
    }).pipe(filter(result => result)).subscribe(res => {
      this.clBlockUI.OnPost();
      this.sapObjectService.ReplicateUdo(objectsToReplicate).pipe(
        finalize(() => this.clBlockUI.Drop()),
        filter(result => result.Data !== null)
      ).subscribe({
        next: ( apiResult: ICLResponse<ISapObjectResStructure[]> ): void => {
          const sapObjectRes: ISapObjectResStructure[] = apiResult.Data;
          const dialogRef: MatDialogRef<ModalSummaryResponseComponent> = this.dialog.open(ModalSummaryResponseComponent, {
            minWidth: '60%',
            height: `75vh`,
            autoFocus: 'first-header',
            disableClose: true,
            data: sapObjectRes
          });

          dialogRef.afterClosed().subscribe((action: string): void => {
            switch (action){
              case 'save':
                this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'La información se ha guardado correctamente' });
                break;
              // Clear all
              default:
                this.connectionService.ClearConnections();
                this.ClearSelectedItemsUDTs();
                this.ClearSelectedItemsUDFs();
                this.formConnexion.reset();
                this.filterForm.reset();
                this.SelectType(0);
                this.listUdts = [];
                this.listUdfs = [];
                this.listUDONames = [];
                this.listUDFsTables = [];
                this.selectedRegistryUDTs = [];
                this.selectedRegistryUDFs = [];
                this.thereDataUDOs = false;
                this.LoadTableDataUDT();
                this.LoadTableDataUDF();
                this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'Se limpió correctamente toda la información' });
              break;
            }
          });
        }
      });
    });
  };

  OpenModalValidValues(data: IUserDefinedField): void{
    if(data.ValidValuesMD.length === 0) return this.alertService.Toast({message:'No cuanta con valores validos'});
    this.dialog.open(ModalValidValuesMDComponent, {
      data: data.ValidValuesMD
    });
  }

  ClearSelectedField(fieldName: string, form?: string): void {
    let applicationField;
    switch (form){
      case 'filter':
        applicationField = this.filterForm.get(fieldName);
        break;
      default:
        applicationField = this.formConnexion.get(fieldName);
    }
    switch (fieldName){
      case 'ApplicationName':
        this.listFilteredApp = this.listApplications;
        break;
    }
    if(applicationField?.value){
      applicationField.setValue('');
    }
  };

  ClearFiltersReplicateTable(): void {
    this.SelectType(0);
    this.filterForm.reset();
    this.thereDataUDOs = false;
    this.listUDONames = [];
    this.listUDFsTables = [];
    switch (this.tabIndex){
      // Tab of UDT
      case 0:
        this.listUdts = [];
        this.selectedRegistryUDTs = [];
        this.ClearSelectedItemsUDTs();
        this.LoadTableDataUDT();
      break;
      // TAB of UDF
      case 1:
        this.listUdfs = [];
        this.selectedRegistryUDFs = [];
        this.ClearSelectedItemsUDFs();
        this.LoadTableDataUDF();
      break;
    };
    this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'Se limpió correctamente la sección' });
  };

  ClearConnectionFields(): void {
    this.formConnexion.reset();
    this.alertService.Toast({ type: CLToastType.SUCCESS, message: 'Se limpió correctamente la conexión' });
  };

  HandleUDONames(): void {
    switch(this.tabIndex){
      // Tab of UDT
      case 0:
        this.listUDONames = Array.from(new Set(this.listUdts.map((udt: IUserDefinedTable): string => udt.TableName)));
        break;
      // TAB of UDF
      case 1:
        this.listUDONames = Array.from(new Set(this.listUdfs.map((udf: IUserDefinedField): string => udf.Name)));
        this.listUDFsTables = Array.from(new Set(this.listUdfs.map((udf: IUserDefinedField): string => udf.TableName)));
        break;
    }
    this.thereDataUDOs = true;
  };

  // #region Dynamic filtering according to the list of UDOs to display
  GetFilteredUDONames(): void {
    this.listFilteredUDONames = this.filterForm.get('NameUDO')!.valueChanges.pipe(
      startWith(''),
      map((value: string | null): string[] =>
        (value ? this.FilterUDONames(value) : this.listUDONames.slice()))
    );
  };

  private FilterUDONames(value: string): string[] {
    const filterValue: string = value?.toLowerCase();
    return this.listUDONames.filter((name: string) => name?.toLowerCase().includes(filterValue));
  };

  GetFilteredUDFsTables(): void {
    this.listFilteredTablesUDFs = this.filterForm.get("TableUDF")!.valueChanges.pipe(
      startWith(''),
      map((value: string | null): string[] => value ? this.FilterUDFsTables(value) : this.listUDFsTables.slice())
    );
  };

  FilterUDFsTables(value: string): string[] {
    const filterValue: string = value?.toLowerCase();
    return this.listUDFsTables.filter((table: string) => table?.toLowerCase().includes(filterValue));
  };

  /**
   * Method to obtain or filter UDOs
   * */
  GetFilteredUDOs(): void {
    // Get the values of the dynamic filters and establish whether they have a valid value
    const filterValueName: string = this.filterForm.get('NameUDO')?.value;
    const filterNameExists: boolean = !!(filterValueName != '' && filterValueName);
    const filterValueTable: string = this.filterForm.get('TableUDF')?.value;
    const filterTablesExists: boolean = !!(filterValueTable != '' && filterValueTable);

    // Apply filters according to the current TAB
    switch(this.tabIndex){
      // Tab of UDT
      case 0:
        if(filterNameExists){
          this.listUdts = this.listUDTsInitial.filter((udt: IUserDefinedTable) =>
            udt?.TableName.toLowerCase().includes(filterValueName.toLowerCase()));
        } else {
          this.listUdts = this.listUDTsInitial;
        }
        this.LoadTableDataUDT();
        break;
      // TAB of UDF
      case 1:
        let filteredUDOsList: IUserDefinedField[] = [...this.listUDFsInitial];

        if(filterNameExists){
          filteredUDOsList = filteredUDOsList.filter((udf: IUserDefinedField) =>
            udf?.Name.toLowerCase().includes(filterValueName.toLowerCase()));
        }

        if(filterTablesExists){
          filteredUDOsList = filteredUDOsList.filter((udf: IUserDefinedField) =>
            udf?.TableName.toLowerCase().includes(filterValueTable.toLowerCase()));
        }

        if(!filterTablesExists && !filterNameExists){
          filteredUDOsList = [...this.listUDFsInitial];
        }

        this.listUdfs = filteredUDOsList;
        this.LoadTableDataUDF();
        break;
    }
  };
  // #endregion
}
