import {HttpClient, HttpParams} from "@angular/common/http";
import {Structures} from "@clavisco/core";
import {Injectable} from "@angular/core";
import {Observable, timeout} from "rxjs";

import {ISapObjectResStructure} from "../models/interfaces/sap-object/i-sap-object-res-structure";
import {ISapUdoHistories} from "../models/interfaces/sap-object/i-sap-udo-histories";
import {ISapUdoHandler} from "../models/interfaces/sap-object/i-sap-udo-handler";
import {IFilterUdo} from "../models/interfaces/sap-object/i-user-defined-field";
import {IUserDefinedTable} from "../models/interfaces/i-user-defined-table";
import {IUserDefinedField} from "../models/interfaces/i-user-defined-field";
import {Filter} from "../models/interfaces/i-user-defined-object-history";
import ICLResponse = Structures.Interfaces.ICLResponse;

@Injectable({
  providedIn: 'root'
})
export class SAPObjectService {
  private readonly CONTROLLER = '/api/SAPObject';
  constructor(
    private http: HttpClient,
  ) { }

  // #region User DefinedOLD
  GetSAPUDOsHistories(params: Filter, _startPos: number, _rowsReturned: number): Observable<ICLResponse<ISapUdoHistories[]>> {
    return this.http.get<ICLResponse<ISapUdoHistories[]>>(`${this.CONTROLLER}/GetUserDefinedObjectsHistory`, {
      params: new HttpParams()
        .set('startPos', _startPos.toString())
        .set('rowsReturned', _rowsReturned.toString())
        .set('filter', JSON.stringify(params))
    })
  }
  // #endregion

  // #region NEW
  Create(ISapUdoHandler: ISapUdoHandler): Observable<ICLResponse<ISapObjectResStructure[]>>{
    return this.http.post<ICLResponse<ISapObjectResStructure[]>>(`${this.CONTROLLER}/Create`, ISapUdoHandler).pipe(timeout(300000));
  }
  // #endregion

  GetUserDefinedFields(filter: IFilterUdo): Observable<ICLResponse<IUserDefinedField[]>> {
    const params: HttpParams = new HttpParams()
      .set('ServerUrl', filter.ServerUrl)
      .set('License', filter.License)
      .set('DataBaseCode', filter.DataBaseCode)
      .set('Password', filter.Password)
      .set('TableName', filter.TableName)
      .set('ApplicationId', filter.ApplicationId)

    return this.http.get<ICLResponse<IUserDefinedField[]>>(`${this.CONTROLLER}/GetUdfsToReplicate`, {params})
  }

  GetUserDefinedTables(filter: IFilterUdo): Observable<ICLResponse<IUserDefinedTable[]>> {
    const params: HttpParams = new HttpParams()
      .set('ServerUrl', filter.ServerUrl)
      .set('License', filter.License)
      .set('DataBaseCode', filter.DataBaseCode)
      .set('Password', filter.Password)
      .set('TableName', filter.TableName)
      .set('ApplicationId', filter.ApplicationId)

    return this.http.get<ICLResponse<IUserDefinedTable[]>>(`${this.CONTROLLER}/GetUdtsToReplicate`, {params})
  }

  ReplicateUdo(ISapUdoHandler: ISapUdoHandler): Observable<ICLResponse<ISapObjectResStructure[]>>{
    return this.http.post<ICLResponse<ISapObjectResStructure[]>>(`${this.CONTROLLER}/ReplicateUdo`, ISapUdoHandler).pipe(timeout(300000));
  }
}
